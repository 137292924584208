@font-face {
  font-family: 'Helvetica Neue';
  src: url("fonts/helvetica-neue/HelveticaNeueRoman.otf") format("opentype");
}
@font-face {
  font-family: 'Univers Else';
  src: url("fonts/univers-else-regular.TTF") format("truetype");
}
@font-face {
  font-family:'Infamous';
  src: url("fonts/infamous-regular-webfont-full/webfonts/infamous-regular.ttf") format('truetype');
}
@font-face {
  font-family:'Deja Vu';
  src: url("fonts/dejavu-sans-mono/DejaVuSansMono.ttf") format('truetype');
}
@font-face {
  font-family:'MyFont';
  src: url("fonts/MyFont/Myfont-Regular.ttf") format('truetype');
}
@font-face {
  font-family:'1942';
  src: url("fonts/1942_report/1942.ttf") format('truetype');
}
@font-face {
  font-family:'Typewriter';
  src: url("fonts/jmh_typewriter/JMH\ Typewriter.ttf") format('truetype');
}
body {
  font-family: 'Univers Else', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: medium;
  color: #3e3e3e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
