:root {
  /* palette */

  --black-color: #3e3e3e;
  --dark-blue: #2d8aa9;
  --medium-blue: #b8dee8;
  --light-blue: #1e1e1e;
  --mint: #d4e5c5;
  --lime: #f0f4bf;
  --sand: #fde3c3;
  --off-white: #F9F8F8;
  --white: #ffffff;
  --firetruck: #fd8e81;
  --salmon: #fbb9b1;
  --light-pink: #fde3dc;
  --light-sand: #fff5ed;
  --beige: #f3eee9;


  /* themes */

  --page-color: var(--off-white);
  --btn-bg-color: var(--page-color);
  --scrollbar-bg-color: var(--dark-gray-color);
  --thumb-bg-color: var(--page-color);

  --text-color: white;
  --secondary-text-color: var(--black-color);

  --image-placeholder-color: var(--light-gray-color);

  /* typography */

  --serif-fonts: Univers-Else,'UniversElse', serif;

  /* spacing system */
  --space-factor: 8px;

}

html {
    --section-background-color: linear-gradient(
      to bottom left,
      rgba(232, 229, 229, 0.582),
      rgba(12, 8, 24, 0.904)
    );
  
    --image-gradient: linear-gradient(
      to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863)
    );
  
    --imp-text-color: var(--black-color);
  }
  
  
  button:focus {
    box-shadow: none !important;
  }

  @media (max-width: 767px){
    h1 {
      font-size: large;
    }
    h2 {
      font-size: small;
    }
    h3 {
      font-size: small;
    }
    .company-name{
      font-size: small;
    }
  }
  p {
    color: var(--black-color);
  }

  body {
    font-size: '8px';
  }

  a:link, a:visited, a:hover, a:active {
    color: var(--black-color) !important;
    text-decoration: none !important;
}

  h1 {
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1.8;
    color: var(--black-color);
  }

  h2 {
    font-size: 2em;
    line-height: 1.8;
    font-weight: 600;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.8;
    font-weight: 500;
  }
  
  /* --------- */
  /*  Preloader */
  /* --------- */
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: var(--white);
    background-image: url(./assets/sketchbook.png);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  #preloader-none {
    opacity: 0;
  }
  
  #no-scroll {
    overflow: hidden;
    height: 100vh;
  }
  
  /* --------- */
  /*Scrollbar   */
  /* --------- */
  
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--dark-gray-color);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--light-blue);
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--light-blue);
    border-radius: 12px;
  }
  
  /* --------- */
  /* Navbar Section  */
  /* --------- */
  .sticky {
    /* background-color: #1b1a2ea9 !important; */
    transition: all 0.3s ease-out 0s !important;
    /* box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important; */
    /* backdrop-filter: blur(15px) !important; */
  }
  
  .navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 0.5rem !important;
    font-size: 1rem !important;
    font-family: 'Univers Else';
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: var(--black-color) !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 1rem 1rem !important;
      font-size: 1rem !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
  }
  .navbar-brand {
    color: var(--light-blue) !important;
  }
  
  .logo {
    height: 1.4em !important;
    width: 2.5em !important;
  }
  
  .navbar-nav .nav-link {
    color: var(--light-blue) !important;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 2px;
    width: 0;
    border-radius: 1px;
    background: var(--light-blue);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }
  
  /* --------- */
  /* Home section */
  /* --------- */

  .stop-motion-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .stop-motion-gallery img {
    max-width: 50vh;
    max-height: 50vh;
    object-fit: contain;
  }

  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
  #tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }  
  
  .home-header {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-top: '45vh';
  }



  /* src/components/AudioPlayer.css */

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  max-width: 400px;
  outline: none;
}



.progress {
  flex: 1;
  cursor: pointer;
  height: 2px;
}

progress {
  width: 100%;
  height: 2px;
  -webkit-appearance: none;
    appearance: none;
}

progress::-webkit-progress-bar {
  background-color: #f0f0f0;
}

progress::-webkit-progress-value {
  background-color: var(--black-color);
}

#progress-bar {
  display: block;
  height: 0px;
  max-width: 200px;
  background-color: var(--black-color);
}

button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #f0f0f0;
}

button img {
  width: 22px;
  height: 22px;
}

.duration_text {
  font-size: small;
}

  @media (max-width: 767px) {
    .home-img-right {
      max-width: 20vw;
    }
    .home-img-left{
      max-width: 20vw;
    }
    .home-content{
      padding: 9vw 0 2vw;
      text-align: left;
    }
  }
  

  .home-img-right{
    padding-top: 10vh;
  }
  

  .photo-caption {
    padding-top: 50px !important;
  }
  
  .home-section {
    display: flex;
    position: relative;
    /* z-index: -1; */
    background-position: top center;
    background-repeat: no-repeat;
    /* padding-bottom: 30px !important;
    padding-top: 30px !important; */
    min-height: 100vh;
    min-width: 100%;
  }
  
  .home-content {
    padding: 9rem 0 2rem !important;
    color: var(--black-color);
    text-align: left;
  }

  .art-section {
    position: relative;
    padding: 80px;
    min-width: 100%;
    min-height: 100vh;
  }
  
  
  .heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
  }
  
  .heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
  }
  
  .main-name {
    color: var(--black-color);
  }
  
  .Typewriter__wrapper {
    margin-top: 0;
    font-size: 1em !important;
    color: var(--black-color) !important;
    font-weight: normal !important;
  }
  .Typewriter__cursor {
    font-size: 1.5em !important;
    color: var(--black-color) !important;
  }
  
  /* @media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 2.4em !important;
      font-weight: 500 !important;
      position: absolute !important;
    }
    .Typewriter__cursor {
      display: none !important;
    }
  } */
  
  #stopmotion-container {
    display: relative;
    max-width: 45vw;
    max-height: 40vh;
  }

  .frame {
      opacity: 0.1;
      transition: opacity 0.2s ease-in-out;
      max-width: '150px';
  }

  .frame.visible {
      opacity: 1;
  }

  .frame:hover {
    opacity: 1;
    z-index: 1000; /* Ensure the hovered image is on top */
  }

  .art:hover {
    transition: 'transform 0.1s ease-in-out';
    scale: 1.2;
    position: absolute;
    max-width: 300px;
  }

  .myAvtar {
    justify-content: center !important;
    padding-top: 9em !important;
  }
  
  @media (max-width: 767px) {
    .myAvtar {
      padding-top: 2em !important;
      padding-bottom: 2em !important;
    }
  }
  
  .home-section-2 {
    /* background: lightblue; */
    background-image: url(./assets/bg_1.png);
    background-size: cover;
    min-width: 99vw;
    min-height: 100vh;
    outline: #1e1e1e solid;
    outline-width: 1px;
    overflow-x: hidden;
  }

  .home-content-2 {
    padding: 9rem 0 2rem !important;
    color: var(--black-color);
  }

  .photos-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 100px;
    padding-top: 200px;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .deck {
    position: absolute;
    width: 430px;
    height: 300px;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
  }
  
  .deck > div {
    touch-action: none;
    background-color: var(--white);
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 85vh;
    max-width: 450px;
    height: 85vh;
    max-height: 320px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.15), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
  
  /* --------- */
  /* Footer */
  /* --------- */
  .footer {
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
  }
  .footer-copywright {
    text-align: center !important;
  }
  
  .footer-body {
    z-index: 1;
    padding-top: 10px !important;
    padding-bottom: 8px;
    text-align: center !important;
  }

  .social-icon{
    display: inline;
    padding: 10px 10px;
    text-decoration: none;
    color: black;
    transition: all 0.4s ease 0s !important;
  }

  .social-icon:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    /* border: 2.2px solid rgba(197, 115, 230, 0.883) !important; */
    box-shadow: 4px 5px 4px 3px var(--medium-blue) !important;
  }
  
  @media (max-width: 767px) {
    .footer-copywright {
      text-align: center !important;
    }
  
    .footer-body {
      text-align: center !important;
    }
  }
  
  .footer h3 {
    font-size: 0.8em;
    color: var(--black-color) !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
  
  .blockquote-footer {
    color: var(--black-color) !important;
  }

  .page-heading {
    color: var(--black-color) !important;
    text-align: left;
    padding: auto;
  }

  /*  */
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 250px); /* Four 250px columns */
    justify-content: center;
  }
  
  .grid-item {
    width: 250px;
    height: 250px;
    display: flex;
    outline-width: 1px;
    outline-style: solid;
    outline-color: red;
    align-items: center;
    justify-content: center;
  }
  
  .grid-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensure the image covers the area without distortion */
  }

  .grid-item img:hover {
    transition: 'transform 0.1s ease-in-out';
    scale: 1.3;
  }

  .intro-blurb {
    transition: opacity 0.3s ease-in-out;
  }
  /* --------- */
  /* Projects */
  /* --------- */

  .project-section {
    position: relative !important;
    padding-top: 80px !important;
    padding-bottom: 30px !important;
    min-height: 100vh;
  }

  .project-content{
    padding-bottom: 30px;
  }

  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
    border-radius: 0;
  }

  .project-card-btn {
    border-color: var(--black-color);
    border-radius: 0;
    border-width: 1px;
    color: var(--black-color) !important;
    font-weight: 500;
    background-color: var(--beige) !important;
    opacity: 0.9 !important;
    padding: 10px !important;
    transition: all 0.5s ease 0s !important;
  }

  .project-card-btn:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px var(--light-blue) !important;
  }
  
  .project-card-view {
    /* box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important; */
    border-radius: 0;
    border-width: 2px;
    color: var(--black-color) !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 90% !important;
    margin-bottom: 20px;
    padding: 10px;
    overflow: hidden !important;
  }

  .project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px var(--light-blue) !important;
  }

  .project-card-title {
    font-weight: 600;
  }

  .project-header {
    padding-top: 10px;
    text-align: left;
    font-weight: 700;
    font-size: 35px;
  }

  .project-caption{
    font-size: 14px;
  }

  
  .grid-item {
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .grid-item img {
    max-width: 200px;
    max-height: 80%;
    object-fit: cover; /* Ensure the image covers the area without distortion */
  }
  
  
  .blog-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .blog-card-view {
    background-color: transparent !important;
    box-shadow: 0 3px 3px 2px var(--light-blue) !important;
    color: white !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  
  .blog-link {
    color: white !important;
    text-decoration: none !important;
  }
  
  .blog-link:hover {
    cursor: pointer !important;
  }
  
  .blog-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 3px 3px 5px var(--light-blue) !important;
  }
  
  .card-img-top {
    padding: 20px !important;
    opacity: 0.8 !important;
    /* border-radius: 10px !important; */
  }
  
  .blog-img {
    padding: 0px !important;
    opacity: 0.8 !important;
    border-radius: 0px !important;
  }
  
  .btn-primary {
    color: var(--black-color) !important;
    background-color: var(--beige) !important;
    border-color: var(--black-color) !important;
    border-radius: 0;
    padding: 10px;
  }
  
  .btn-primary:hover {
    color: var(--white) !important;
    background-color: var(--black-color) !important;
    border-color: var(--beige) !important;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .project-heading {
    color: black !important;
    font-size: 2.3em !important;
    font-weight: 600 !important;
    padding-top: 10px !important;
  }
  
  /* --------- */
  /* Profile */
  /* --------- */
  @media(max-width: 767px){
    .section-link{
      font-size: small;
    }
    .harv-logo{
      max-width: 40vw;
    }
  }


  .about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
    min-height: 100vh;
  }

  .profile-sections {
    scroll-snap-type: y mandatory;
  }

  .profile-container{
    scroll-snap-type: y mandatory;
    height: 100vh;
    /* overflow-y: scroll; */
  }

  .profile-section {
    position: relative !important;
    padding-top: 80px !important;
    padding-bottom: 30px !important;
    min-height: 100vh;
  }

  .profile-content {
    padding: 9rem 0 2rem !important;
    color: var(--black-color);
    text-align: left;
  }

  .table-of-contents {
    padding: 40px;
    margin: 20px;
  }
  
  .section-link {
    display: block;
  }

  .scroll-link {
    text-decoration: none;
    color: var(--black-color);
    font-size: 2em;
  }

  .scroll-link:hover{
    cursor: pointer;
  }

  .react-pdf__Page__canvas {
    max-height: 90vh !important;
    margin: 0 auto;
  }

  .react-pdf__Document {
    text-align: center;
    outline: var(--black-color);
  }

  .file-directory {
    padding: 10px;
    border-right: 1px solid #ddd;
  }
  
  .info-link {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    padding-left: 20px; /* Indentation for links */
  }
